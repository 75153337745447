<template>
  <label-selector
    v-if="isMultiple"
    :all-labels="allLabels"
    :size="size"
    :saved-labels="savedLabels"
    :add-button-classes="addButtonClasses"
    :add-button-variant="addButtonVariant"
    @add="addItem"
    @remove="removeItem"
    @toggle-labels="toggleLabels"
  />
  <label-selector
    v-else
    :all-labels="allLabels"
    :saved-labels="savedLabels"
    @add="addItem"
    @remove="removeItem"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import LabelSelector from 'dashboard/components/widgets/LabelSelector.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { LabelSelector },
  mixins: [alertMixin],
  props: {
    contactId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    contactsIds: {
      default: () => [],
      type: Array,
      required: false,
    },
    size: {
      type: String,
      default: 'tiny',
    },
    addButtonClasses: {
      type: String,
      default: '',
    },
    addButtonVariant: {
      type: String,
      default: 'smooth',
    },
  },

  computed: {
    allAvailableContactLabels() {
      return this.contactsIds.map(contactId =>
        this.$store.getters['contactLabels/getContactLabels'](contactId)
      );
    },
    savedLabels() {
      if (this.isMultiple) {
        let availableContactLabels = [
          ...new Set(this.allAvailableContactLabels.flat()),
        ];
        availableContactLabels = availableContactLabels.filter(i =>
          this.allAvailableContactLabels.every(a => a.includes(i))
        );
        return this.allLabels.filter(({ title }) => {
          return availableContactLabels.includes(title);
        });
      }
      const availableContactLabels = this.$store.getters[
        'contactLabels/getContactLabels'
      ](this.contactId);

      return this.allLabels.filter(({ title }) =>
        availableContactLabels.includes(title)
      );
    },
    isMultiple() {
      return this.contactsIds && this.contactsIds.length > 0;
    },
    ...mapGetters({
      labelUiFlags: 'contactLabels/getUIFlags',
      allLabels: 'labels/getLabels',
    }),
  },
  watch: {
    contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.fetchLabels(newContactId);
      }
    },
    contactsIds(newContactsIds, prevContactsIds) {
      if (
        !!newContactsIds &&
        JSON.stringify(newContactsIds) !== JSON.stringify(prevContactsIds)
      ) {
        newContactsIds.forEach(id => this.fetchLabels(id));
      }
    },
  },
  mounted() {
    const { contactId, contactsIds } = this;
    if (contactId) {
      this.fetchLabels(contactId);
    } else if (!!contactsIds && contactsIds.length > 0) {
      contactsIds.forEach(id => this.fetchLabels(id));
    }
  },
  methods: {
    async onUpdateLabels(selectedLabels) {
      try {
        await this.$store.dispatch('contactLabels/update', {
          contactId: this.contactId,
          labels: selectedLabels,
        });
      } catch (error) {
        this.showAlert(this.$t('CONTACT_PANEL.LABELS.CONTACT.ERROR'));
      }
    },
    toggleLabels() {
      this.$emit('toggle-labels');
    },

    getAvailableContactLabels(contactId) {
      return [
        ...this.$store.getters['contactLabels/getContactLabels'](contactId),
      ];
    },
    async addToMultiple(value) {
      try {
        const tasks = this.contactsIds.map(contactId => {
          const labels = this.getAvailableContactLabels(contactId);
          if (!labels.includes(value.title)) {
            labels.push(value.title);
            return this.$store.dispatch('contactLabels/update', {
              contactId,
              labels,
            });
          }
          return Promise.resolve();
        });
        await Promise.all(tasks);
        this.showAlert(this.$t('CONTACT_PANEL.LABELS.CONTACT.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('CONTACT_PANEL.LABELS.CONTACT.ERROR'));
      }
    },
    async addItem(value) {
      if (this.isMultiple) {
        await this.addToMultiple(value);
      } else {
        const result = this.savedLabels.map(item => item.title);
        result.push(value.title);
        await this.onUpdateLabels(result);
      }
    },

    async removeFromMultiple(value) {
      try {
        const tasks = this.contactsIds.map(contactId => {
          const labels = this.savedLabels
            .map(label => label.title)
            .filter(label => label !== value);
          return this.$store.dispatch('contactLabels/update', {
            contactId,
            labels,
          });
        });
        await Promise.all(tasks);
      } catch (error) {
        this.showAlert(this.$t('CONTACT_PANEL.LABELS.CONTACT.ERROR'));
      }
    },
    async removeItem(value) {
      if (this.isMultiple) {
        await this.removeFromMultiple(value);
      } else {
        const result = this.savedLabels
          .map(label => label.title)
          .filter(label => label !== value);
        await this.onUpdateLabels(result);
      }
    },
    async fetchLabels(contactId) {
      if (!contactId) {
        return;
      }
      await this.$store.dispatch('contactLabels/get', contactId);
    },
  },
};
</script>

<style></style>
