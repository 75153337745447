import types from '../mutation-types';
import CustomAttributesReports from '../../api/customAttributesReports';
import { downloadCsvFile, downloadXlsxFile } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  isFetching: false,
  isInfoFetching: false,
  meta: {
    total_contacts: 0,
    contacts_with_attributes: 0,
    contacts_without_attributes: 0,
    total_attributes: 0,
  },
  metrics: [],
  customAttribute: {},
  records: [],
};

export const getters = {
  getMetrics(_state) {
    return _state.metrics;
  },
  getIsFetching(_state) {
    return _state.isFetching;
  },
  getMeta(_state) {
    return _state.meta;
  },
  getRecords(_state) {
    return _state.records;
  },
  getCustomAttributeInfo(_state) {
    return _state.customAttribute;
  },
  getIsInfoFetching(_state) {
    return _state.isInfoFetching;
  },
};

export const actions = {
  get: async function get({ commit }, params) {
    commit(types.TOGGLE_CUSTOM_ATTRIBUTES_REPORT_INFO_LOADING, true);
    try {
      const response = await CustomAttributesReports.show(params);
      commit(types.SET_CUSTOM_ATTRIBUTES_INFO_REPORT, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.TOGGLE_CUSTOM_ATTRIBUTES_REPORT_INFO_LOADING, false);
    }
  },
  getMetrics: async function getMetrics({ commit }, params) {
    commit(types.TOGGLE_CUSTOM_ATTRIBUTES_REPORT_LOADING, true);
    try {
      const response = await CustomAttributesReports.getMetrics(params);
      commit(types.SET_CUSTOM_ATTRIBUTES_REPORT, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.TOGGLE_CUSTOM_ATTRIBUTES_REPORT_LOADING, false);
    }
  },
  downloadCustomAttributesReports(_, params) {
    CustomAttributesReports.download(params).then(response => {
      if (params.fileFormat === 'xlsx') {
        downloadXlsxFile(params.fileName, response.data);
      } else {
        downloadCsvFile(params.fileName, response.data);
      }
    });
    AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
      reportType: 'custom_attributes',
    });
  },
};

export const mutations = {
  [types.TOGGLE_CUSTOM_ATTRIBUTES_REPORT_LOADING](_state, flag) {
    _state.isFetching = flag;
  },
  [types.SET_CUSTOM_ATTRIBUTES_REPORT]: (_state, { meta, metrics } = {}) => {
    _state.metrics = metrics;
    _state.meta = meta;
  },
  [types.TOGGLE_CUSTOM_ATTRIBUTES_REPORT_INFO_LOADING](_state, flag) {
    _state.isFetching = flag;
  },
  [types.SET_CUSTOM_ATTRIBUTES_INFO_REPORT]: (
    _state,
    { custom_attribute, records } = {}
  ) => {
    _state.customAttribute = custom_attribute;
    _state.records = records;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
