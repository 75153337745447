<template>
  <div
    id="group-button-wrapper"
    :class="wrapperClasses"
    data-dropup-auto="false"
  >
    <select
      id="dropdown-select"
      v-model="value"
      :class="classes"
      name="dropdown-select"
      :disabled="isDisabled"
    >
      <option v-for="option in options" :key="option.key">
        {{ option.value }}
      </option>
    </select>
    <woot-button
      :icon="icon"
      size="size"
      :variant="variant"
      :color-scheme="buttonColor"
      :is-disabled="isDisabled"
      class="btn-group-button"
      @click="handleClick"
    >
      {{ label }}
    </woot-button>
  </div>
</template>
<script>
import WootButton from '../../dashboard/components/ui/WootButton.vue';

export default {
  name: 'GroupButton',
  components: { WootButton },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    colorScheme: {
      type: String,
      default: 'primary',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    classNames: {
      type: [String, Object],
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.selected,
    };
  },
  computed: {
    variantClasses() {
      if (this.variant.includes('link')) {
        return `clear ${this.variant}`;
      }
      return this.variant;
    },

    buttonColor() {
      return this.isDisabled ? 'secondary' : this.colorScheme;
    },

    wrapperClasses() {
      return ['button', 'group-button-wrapper', this.classNames];
    },

    classes() {
      const color = this.isDisabled ? 'secondary' : this.colorScheme;
      return [
        'button',
        this.variantClasses,
        this.size,
        color,
        this.isDisabled ? 'disabled' : '',
        this.isExpanded ? 'expanded' : '',
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~widget/assets/scss/variables';

#group-button-wrapper:dir(rtl) {
  flex-direction: row-reverse;
  max-width: 5rem;
  .btn-group-button {
    padding: 2px 10px 2px 20px;
  }
}

.group-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  align-items: center;
  border-radius: 2px;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.mx-4 {
  min-width: 10rem;
  right: 4rem;

  #dropdown-select {
    min-width: 10rem;
  }
}

.btn-group-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px 4px 2px 10px;
}

#dropdown-select {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: 2px 20px 2px 10px;
}
</style>
