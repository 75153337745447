<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
    <div :class="wrapperClass">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <woot-sidemenu-icon />
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
      </div>
      <div class="flex gap-2">
        <div
          class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap"
        >
          <div class="flex items-center absolute h-full left-2.5">
            <fluent-icon
              icon="search"
              class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200"
            />
          </div>

          <input
            type="text"
            :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search border-slate-100 dark:border-slate-600"
            :value="searchQuery"
            @keyup.enter="submitSearch"
            @input="inputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="submitSearch"
          >
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
        <div v-if="hasActiveSegments" class="flex gap-2">
          <woot-button
            class="clear"
            color-scheme="secondary"
            icon="edit"
            @click="onToggleEditSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_EDIT') }}
          </woot-button>
          <woot-button
            class="clear"
            color-scheme="alert"
            icon="delete"
            @click="onToggleDeleteSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_DELETE') }}
          </woot-button>
        </div>
        <div v-if="!hasActiveSegments" class="relative">
          <div
            v-if="hasAppliedFilters"
            class="absolute h-2 w-2 top-1 right-3 bg-slate-500 dark:bg-slate-500 rounded-full"
          />
          <woot-button
            class="clear"
            color-scheme="secondary"
            data-testid="create-new-contact"
            icon="filter"
            @click="toggleFilter"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS') }}
          </woot-button>
        </div>

        <woot-button
          v-if="hasAppliedFilters && !hasActiveSegments"
          class="clear"
          color-scheme="alert"
          variant="clear"
          icon="save"
          @click="onToggleSegmentsModal"
        >
          {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_SAVE') }}
        </woot-button>
        <contact-label
          v-if="selectedContacts.length"
          :contacts-ids="selectedContacts"
          :class="contactsLabelsClasses"
          add-button-variant=""
          add-button-classes="clear"
          size="medium"
          @toggle-labels="onToggleLabels"
        />
        <woot-button
          v-if="isImportExportPanelDisabled"
          class="clear"
          color-scheme="success"
          icon="person-add"
          data-testid="create-new-contact"
          @click="toggleCreate"
        >
          {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
        </woot-button>

        <woot-button
          v-if="isImportExportPanelDisabled"
          color-scheme="info"
          icon="upload"
          class="clear"
          @click="toggleImport"
        >
          {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
        <woot-button
          v-if="isImportExportPanelDisabled"
          color-scheme="info"
          icon="download"
          class="clear"
          @click="submitExport"
        >
          {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
        <woot-button
          v-if="isMultipleSelection && isAdmin"
          v-tooltip.bottom-end="$t('DELETE_MULTIPLE_CONTACTS.BUTTON_LABEL')"
          title="$t('DELETE_MULTIPLE_CONTACTS.BUTTON_LABEL')"
          icon="delete"
          size="medium"
          color-scheme="alert"
          :disabled="uiFlags.isDeleting"
          class="clear"
          @click="toggleDeleteModal"
        />
      </div>
    </div>

    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_MULTIPLE_CONTACTS.CONFIRM.TITLE')"
      :message="
        $t('DELETE_MULTIPLE_CONTACTS.CONFIRM.MESSAGE', {
          numberOfContacts: selectedContacts.length,
        })
      "
      :confirm-text="$t('DELETE_MULTIPLE_CONTACTS.CONFIRM.YES')"
      :reject-text="$t('DELETE_MULTIPLE_CONTACTS.CONFIRM.NO')"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import alertMixin from 'shared/mixins/alertMixin';
import WootButton from '../../../../components/ui/WootButton.vue';
import ContactLabel from 'dashboard/routes/dashboard/contacts/components/ContactLabels.vue';

export default {
  components: { WootButton, ContactLabel },
  mixins: [adminMixin, alertMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
    selectedContacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedAll: false,
      showCreateModal: false,
      showImportModal: false,
      showDeleteModal: false,
      contactsLabelsClasses: 'contact-labels',
    };
  },
  computed: {
    wrapperClass() {
      const justify = this.isMultipleSelection
        ? 'justify-start'
        : 'justify-between';
      return `flex w-full py-2 px-4 ${justify}`;
    },
    isMultipleSelection() {
      return !!this.selectedContacts && this.selectedContacts.length > 0;
    },
    isImportExportPanelDisabled() {
      return !this.isMultipleSelection && this.isAdmin;
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      uiFlags: 'contacts/getUIFlags',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
  },
  methods: {
    onToggleLabels() {
      if (this.contactsLabelsClasses.includes('px-6')) {
        this.contactsLabelsClasses = 'contact-labels clear';
      } else {
        this.contactsLabelsClasses = 'contact-labels clear px-6';
      }
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async confirmDeletion() {
      await this.deleteContacts(this.selectedContacts);
      this.closeDelete();
    },

    async deleteContacts(contactsIds) {
      try {
        await this.$store.dispatch('contacts/bulkDelete', contactsIds);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_MULTIPLE_CONTACTS.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_MULTIPLE_CONTACTS.API.ERROR_MESSAGE')
        );
      }
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },
    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleCreate() {
      this.$emit('on-toggle-create');
    },
    toggleFilter() {
      this.$emit('on-toggle-filter');
    },
    toggleImport() {
      this.$emit('on-toggle-import');
    },
    submitExport() {
      this.$emit('on-export-submit');
    },
    submitSearch() {
      this.$emit('on-search-submit');
    },
    inputSearch(event) {
      this.$emit('on-input-search', event);
    },
    deselectAll(event) {
      this.selectedAll = false;
      this.$emit('on-deselect-all', event);
    },
    selectAll(event) {
      this.selectedAll = true;
      this.$emit('on-select-all', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}
</style>
