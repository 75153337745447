<template>
  <div class="relative editor-root">
    <variable-list
      v-if="shouldShowVariables"
      :search-key="variableSearchTerm"
      @click="insertVariable"
    />
    <input v-model="textValue" type="text" class="variable-input" />
  </div>
</template>

<script>
import VariableList from '../conversation/VariableList.vue';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'WootCampaignEditor',
  components: { VariableList },
  mixins: [eventListenerMixins, uiSettingsMixin, alertMixin],
  props: {
    value: { type: String, default: '' },
    editorId: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    isPrivate: { type: Boolean, default: false },
    enableSuggestions: { type: Boolean, default: true },
    enableSuggestionsOnlyTo: { type: String, default: '' },
    overrideLineBreaks: { type: Boolean, default: false },
    updateSelectionWith: { type: String, default: '' },
    enableVariables: { type: Boolean, default: false },
    enableCannedResponses: { type: Boolean, default: true },
    variables: { type: Object, default: () => ({}) },
    enabledMenuOptions: { type: Array, default: () => [] },
    signature: { type: String, default: '' },
    allowSignature: { type: Boolean, default: false },
    showImageResizeToolbar: { type: Boolean, default: false }, // A kill switch to show or hide the image toolbar
  },
  data() {
    return {
      showUserMentions: false,
      showCannedMenu: false,
      showVariables: false,
      mentionSearchKey: '',
      cannedSearchTerm: '',
      variableSearchTerm: '',
      textValue: '',
      editorView: null,
      range: null,
      state: undefined,
      isImageNodeSelected: false,
      toolbarPosition: { top: 0, left: 0 },
      selectedImageNode: null,
    };
  },
  computed: {
    shouldShowVariables() {
      return this.showVariables;
    },
  },
  watch: {
    textValue(val) {
      if (/{{([^{}]*)$/.test(val) && !val.endsWith('}}')) {
        this.showVariables = true;
        this.variableSearchTerm = val.match(/{{([^{}]*)$/)[1];
      } else {
        this.showVariables = false;
      }
      this.$emit('valChange', this.textValue);
    },
  },
  methods: {
    insertVariable(variable) {
      this.textValue = this.textValue.replace(
        /(.*)(\{{)([^{}]*)$/,
        (match, p1) => p1 + '{{' + variable + '}}'
      );
      this.showVariables = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@chatwoot/prosemirror-schema/src/styles/base.scss';

.variable-input {
  @apply w-full mt-2.5;
}

.ProseMirror-menubar-wrapper {
  @apply flex flex-col;
  .ProseMirror-menubar {
    min-height: var(--space-two) !important;
    @apply -ml-2.5 pb-0 bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-100;

    .ProseMirror-menu-active {
      @apply bg-slate-75 dark:bg-slate-800;
    }
  }
  > .ProseMirror {
    @apply p-0 break-words text-slate-800 dark:text-slate-100;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      @apply text-slate-800 dark:text-slate-100;
    }

    blockquote {
      @apply border-slate-400 dark:border-slate-500;

      p {
        @apply text-slate-600 dark:text-slate-400;
      }
    }

    ol li {
      @apply list-item list-decimal;
    }
  }
}

.editor-root {
  @apply w-full relative m-0 p-0;
}

.ProseMirror-woot-style {
  @apply overflow-auto min-h-[5rem] max-h-[7.5rem];
}

.ProseMirror-prompt {
  @apply z-50 bg-slate-25 dark:bg-slate-700 rounded-md border border-solid border-slate-75 dark:border-slate-800;

  h5 {
    @apply dark:text-slate-25 text-slate-800;
  }
}

.is-private {
  .prosemirror-mention-node {
    @apply font-medium bg-yellow-100 dark:bg-yellow-800 text-slate-900 dark:text-slate-25 py-0 px-1;
  }

  .ProseMirror-menubar-wrapper {
    .ProseMirror-menubar {
      @apply bg-yellow-100 dark:bg-yellow-800 text-slate-700 dark:text-slate-25;
    }

    > .ProseMirror {
      @apply text-slate-800 dark:text-slate-25;

      p {
        @apply text-slate-800 dark:text-slate-25;
      }
    }
  }
}

.editor-wrap {
  @apply mb-4;
}

.message-editor {
  @apply border border-solid border-slate-200 dark:border-slate-600 bg-white dark:bg-slate-900 rounded-md py-0 px-1 mb-0;
}

.editor_warning {
  @apply border border-solid border-red-400 dark:border-red-400;
}

.editor-warning__message {
  @apply text-red-400 dark:text-red-400 font-normal text-sm pt-1 pb-0 px-0;
}
</style>
