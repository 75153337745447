<template>
  <footer
    v-if="isFooterVisible"
    class="bg-white dark:bg-slate-800 h-[60px] border-t border-solid border-slate-75 dark:border-slate-700/50 flex items-center justify-between py-0 px-4"
  >
    <div class="left-aligned-wrap">
      <div class="text-xs text-slate-600 dark:text-slate-200">
        <strong>{{ firstIndex }}</strong>
        - <strong>{{ lastIndex }}</strong> {{ $t('CONTACTS_PAGE.OF') }}
        <strong>{{ totalCount }}</strong> {{ $t('CONTACTS_PAGE.ITEMS') }}
      </div>
    </div>
    <single-select-dropdown
      :label="$t('CONTACTS_PAGE.PAGE_SIZE')"
      :selected="pageSize.toString()"
      :options="pageSizeOptionsWithValues"
      :action="onPageSizeChange"
      wrapper-class="flex text-center gap-0"
      label-class="flex text-center items-center max-w-[80px] min-w-[75px] text-xs text-center text-slate-600 dark:text-slate-200"
      drop-down-class="flex text-center w-full max-w-[100px] min-w-[50px] text-xs text-slate-600 dark:text-slate-200 max-h-[35px] m-0"
      option-class="text-center"
    />
    <div class="right-aligned-wrap">
      <div
        v-if="totalCount"
        class="primary button-group pagination-button-group"
      >
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :class-names="leftGoToClass.className"
          :is-disabled="hasFirstPage"
          @click="onFirstPage"
        >
          <fluent-icon :icon="leftGoToClass.icon" size="18" />
          <fluent-icon
            :icon="leftGoToClass.icon"
            size="18"
            :class="pageFooterIconClass"
          />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasPrevPage"
          @click="onPrevPage"
        >
          <fluent-icon :icon="leftGoToClass.icon" size="18" />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          @click.prevent
        >
          {{ currentPage }}
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :is-disabled="hasNextPage"
          @click="onNextPage"
        >
          <fluent-icon :icon="rigthGoToClass.icon" size="18" />
        </woot-button>
        <woot-button
          size="small"
          variant="smooth"
          color-scheme="secondary"
          :class-names="rigthGoToClass.className"
          :is-disabled="hasLastPage"
          @click="onLastPage"
        >
          <fluent-icon :icon="rigthGoToClass.icon" size="18" />
          <fluent-icon
            :icon="rigthGoToClass.icon"
            size="18"
            :class="pageFooterIconClass"
          />
        </woot-button>
      </div>
    </div>
  </footer>
</template>

<script>
import rtlMixin from 'shared/mixins/rtlMixin';
import SingleSelectDropdown from '../../routes/dashboard/settings/inbox/components/SingleSelectDropdown.vue';

export default {
  components: { SingleSelectDropdown },
  mixins: [rtlMixin],
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    pageSizeOptions: {
      type: Array,
      default: () => ['10', '25', '50', '100'],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    pageFooterIconClass() {
      return this.isRTLView
        ? 'margin-right-minus-slab'
        : 'margin-left-minus-slab';
    },
    pageSizeOptionsWithValues() {
      return [
        ...this.pageSizeOptions,
        this.$t('CONTACTS_PAGE.ALL_CONTACTS'),
      ].map(option => ({
        key: option.toString(),
        value: option.toString(),
      }));
    },
    leftGoToClass() {
      return this.isRTLView
        ? {
            className: 'goto-last',
            icon: 'chevron-right',
          }
        : {
            className: 'goto-first',
            icon: 'chevron-left',
          };
    },
    rigthGoToClass() {
      return this.isRTLView
        ? {
            className: 'goto-first',
            icon: 'chevron-left',
          }
        : {
            className: 'goto-last',
            icon: 'chevron-right',
          };
    },
    isFooterVisible() {
      return this.totalCount && !(this.firstIndex > this.totalCount);
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return Math.min(this.totalCount, this.pageSize * this.currentPage);
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    hasLastPage() {
      return !!Math.ceil(this.totalCount / this.pageSize);
    },
    hasFirstPage() {
      return this.currentPage === 1;
    },
    hasNextPage() {
      return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
    },
    hasPrevPage() {
      return this.currentPage === 1;
    },
  },
  methods: {
    onNextPage() {
      if (this.hasNextPage) {
        return;
      }
      const newPage = this.currentPage + 1;
      this.onPageChange(newPage);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        return;
      }
      const newPage = this.currentPage - 1;
      this.onPageChange(newPage);
    },
    onFirstPage() {
      if (this.hasFirstPage) {
        return;
      }
      const newPage = 1;
      this.onPageChange(newPage);
    },
    onLastPage() {
      if (this.hasLastPage) {
        return;
      }
      const newPage = Math.ceil(this.totalCount / this.pageSize);
      this.onPageChange(newPage);
    },
    onPageChange(page) {
      this.$emit('page-change', page);
    },
    parsedPageSize(selectedPageSize) {
      return selectedPageSize
        .toString()
        .toLowerCase()
        .includes(
          this.$t('CONTACTS_PAGE.ALL_CONTACTS').toString().toLowerCase()
        )
        ? this.totalCount
        : parseInt(selectedPageSize.toString(), 10);
    },
    onPageSizeChange(selectedPageSize) {
      this.$emit('page-size-change', this.parsedPageSize(selectedPageSize));
    },
  },
};
</script>

<style lang="scss" scoped>
.goto-first,
.goto-last {
  i:last-child {
    @apply -ml-1;
  }
}
</style>
