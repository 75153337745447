<template>
  <div class="flex-1 overflow-auto p-4 contacts-table-wrap">
    <report-filter-selector
      :show-agents-filter="false"
      :show-inbox-filter="false"
      :show-rating-filter="false"
      :show-team-filter="false"
      :show-labels-filter="true"
      :show-business-hours-switch="false"
      :show-date-range-filter="false"
      :show-custom-attributes-filter="true"
      @filter-change="onFilterChange"
    />
    <group-button
      :options="downloadOptions"
      icon="arrow-download"
      class-names="button--fixed-top mx-4"
      color-scheme="success"
      selected="XLSX"
      :is-disabled="isDownloadDisabled"
      size="medium"
      @click="downloadReports"
    />
    <div v-if="isFetching" class="items-center flex text-base justify-center">
      <spinner />
      <span>{{ $t('REPORT.LOADING_CHART') }}</span>
    </div>
    <empty-state
      v-else-if="isEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    />
    <ve-table
      v-else
      :key="tableKey"
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      scroll-width="187rem"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import ReportFilterSelector from './components/FilterSelector.vue';
import { generateFileName } from '../../../../helper/downloadHelper';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import GroupButton from '../../../../../shared/components/GroupButton.vue';
import { DOWNLOAD_FORMATS, GROUP_BY_FILTER } from './constants';
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import rtlMixin from 'shared/mixins/rtlMixin';
import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';

export default {
  name: 'CustomAttributesReports',
  components: {
    VeTable,
    GroupButton,
    ReportFilterSelector,
    Spinner,
    EmptyState,
  },
  mixins: [alertMixin, rtlMixin, clickaway],
  data() {
    return {
      pageIndex: 1,
      from: 0,
      userIds: [],
      inbox: null,
      team: null,
      rating: null,
      downloadOptions: DOWNLOAD_FORMATS,
      groupBy: GROUP_BY_FILTER[1],
      label: null,
      customAttribute: null,
      componentKey: 0,
      sortParam: 'name',
      sortOrder: 'asc',
      tableKey: 0,
      sortConfig: {},
      sortPriority: {},
      sortOption: {
        sortAlways: true,
        multipleSort: true,
        sortChange: params => this.onSortChange(params),
      },
    };
  },
  computed: {
    ...mapGetters({
      attribute: 'customAttributes/getCustomAttributeInfo',
      records: 'customAttributes/getRecords',
      isFetching: 'customAttributes/getIsInfoFetching',
    }),
    isDownloadDisabled() {
      return !this.label;
    },
    to() {
      return getUnixTime(startOfDay(new Date()));
    },
    isEmptyState() {
      return !this.isFetching && (!this.records || !this.records.length);
    },
    requestPayload() {
      return {
        from: this.from,
        to: this.to,
        user_ids: this.userIds,
        inbox_id: this.inbox,
        team_id: this.team,
        rating: this.rating,
      };
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.NAME'),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.name || '',
          width: 300,
          renderBodyCell: ({ row }) => (
            <div class="flex gap-2 items-center row--user-block">
              <Thumbnail
                src={row.thumbnail}
                size="32px"
                username={row.name}
                status={row.availability_status}
              />
              <h6 class="sub-block-title overflow-hidden whitespace-nowrap text-ellipsis primary">
                {row.name}
              </h6>
            </div>
          ),
        },
        {
          field: 'email',
          key: 'email',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.EMAIL_ADDRESS'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.email || '',
          width: 240,
          renderBodyCell: ({ row }) => {
            if (row.email)
              return (
                <div class="overflow-hidden whitespace-nowrap text-ellipsis text-woot-500 dark:text-woot-500">
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href={`mailto:${row.email}`}
                  >
                    {row.email}
                  </a>
                </div>
              );
            return '---';
          },
        },
        {
          field: 'phone_number',
          key: 'phone_number',
          width: 240,
          sortBy: this.sortConfig.phone_number || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.PHONE_NUMBER'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'attribute',
          key: 'attribute',
          sortBy: this.sortConfig.attribute || '',
          title: this.$t(
            'CONTACTS_PAGE.LIST.TABLE_HEADER.CUSTOM_ATTRIBUTE_VALUE'
          ),
          align: this.isRTLView ? 'right' : 'left',
        },
      ];
    },
    tableDataRows() {
      if (this.isFetching) {
        return [];
      }
      return this.records.map(item => {
        return {
          ...item,
          name: item.name || '---',
          phone_number: item.phone_number || '---',
          email: item.email || '---',
          attribute:
            item.custom_attributes[this.customAttribute.attribute_key] || '---',
        };
      });
    },
    tableData() {
      let data = this.tableDataRows;
      Object.keys({ ...this.sortPriority }).forEach(key => {
        data = this.sort(data, key, this.sortConfig[key]);
      });
      return data;
    },
  },
  watch: {
    sortConfig(newSortConfig, oldSortConfig) {
      if (JSON.stringify(newSortConfig) !== JSON.stringify(oldSortConfig)) {
        this.tableKey = (this.tableKey + 1) % 2;
      }
    },
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    sort(collection, param, order) {
      return collection.sort((a, b) => {
        if (order === 'asc') {
          return a[param] > b[param] ? 1 : -1;
        }
        return a[param] < b[param] ? 1 : -1;
      });
    },
    onSortChange(params) {
      if (!this.sortConfig || Object.keys(this.sortConfig).length === 0) {
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            this.sortPriority[key] = 1;
          }
        });
      } else {
        const priorities = Object.values(this.sortPriority);
        const biggestPriority = Math.max(...priorities);
        const newSortField = Object.keys(params).find(
          k => !this.sortConfig[k] || params[k] !== this.sortConfig[k]
        );
        this.sortPriority[newSortField] = biggestPriority + 1;
      }
      Object.entries(this.sortPriority)
        .sort(([, a], [, b]) => a - b)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      this.sortConfig = params;
    },
    getAllData() {
      try {
        this.$store.dispatch('customAttributes/get', {
          id: this.customAttribute?.id || null,
          label: this.label?.title || null,
        });
        this.componentKey += 1;
      } catch {
        this.showAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
      }
    },
    downloadReports(fileFormat) {
      const type = `custom_attributes_info_${this.label.title}`;
      fileFormat = fileFormat.toLowerCase();
      try {
        this.$store.dispatch(
          'customAttributes/downloadCustomAttributesReports',
          {
            fileName: generateFileName({ type, to: this.to, fileFormat }),
            fileFormat,
            label: this.label?.title || null,
            locale: this.$root.$i18n.locale,
          }
        );
      } catch (error) {
        this.showAlert(this.$t('REPORT.CSAT_REPORTS.DOWNLOAD_FAILED'));
      }
    },

    onFilterChange({ selectedLabel, selectedCustomAttribute }) {
      this.label = selectedLabel;
      this.customAttribute = selectedCustomAttribute;
      this.getAllData();
    },
  },
};
</script>
<style lang="scss" scoped>
.contacts-table-wrap::v-deep {
  .ve-table {
    @apply pb-8;
  }

  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-slate-600 dark:text-slate-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-sort {
    @apply -top-1;
  }
}

.cell--social-profiles {
  a {
    @apply text-slate-300 dark:text-slate-400 text-lg min-w-[2rem] text-center;
  }
}
</style>
