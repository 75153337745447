<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOption"
      class="no-margin"
      :placeholder="$t('CUSTOM_ATTRIBUTES_REPORTS.FILTER_DROPDOWN_LABEL')"
      label="attribute_display_name"
      track-by="id"
      :options="options"
      :option-height="24"
      :show-labels="false"
      @input="handleInput"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsFiltersCustomAttributes',
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
    ...mapGetters({
      options: 'attributes/getAttributes',
    }),
  },
  mounted() {
    this.$store.dispatch('attributes/get');
  },
  methods: {
    handleInput() {
      this.$emit('custom-attributes-filter-selection', this.selectedOption);
    },
  },
};
</script>
