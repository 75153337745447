<template>
  <div class="flex-1 overflow-auto p-4">
    <report-filter-selector
      :show-agents-filter="false"
      :show-inbox-filter="false"
      :show-rating-filter="false"
      :show-team-filter="false"
      :show-labels-filter="true"
      :show-business-hours-switch="false"
      :show-date-range-filter="false"
      @filter-change="onFilterChange"
    />
    <group-button
      :options="downloadOptions"
      icon="arrow-download"
      class-names="button--fixed-top mx-4"
      color-scheme="success"
      selected="XLSX"
      :is-disabled="isDownloadDisabled"
      size="medium"
      @click="downloadReports"
    />
    <custom-attributes-reports-container
      :key="componentKey"
      :selected-label="label"
    />
  </div>
</template>
<script>
import ReportFilterSelector from './components/FilterSelector.vue';
import { generateFileName } from '../../../../helper/downloadHelper';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import GroupButton from '../../../../../shared/components/GroupButton.vue';
import { DOWNLOAD_FORMATS, GROUP_BY_FILTER } from './constants';
import CustomAttributesReportsContainer from './CustomAttributesReportsContainer.vue';

export default {
  name: 'CustomAttributesReports',
  components: {
    CustomAttributesReportsContainer,
    GroupButton,
    ReportFilterSelector,
  },
  mixins: [alertMixin],
  data() {
    return {
      pageIndex: 1,
      from: 0,
      to: 0,
      userIds: [],
      inbox: null,
      team: null,
      rating: null,
      downloadOptions: DOWNLOAD_FORMATS,
      groupBy: GROUP_BY_FILTER[1],
      label: null,
      componentKey: 0,
    };
  },
  computed: {
    isDownloadDisabled() {
      return !this.label;
    },
    requestPayload() {
      return {
        from: this.from,
        to: this.to,
        user_ids: this.userIds,
        inbox_id: this.inbox,
        team_id: this.team,
        rating: this.rating,
      };
    },
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    getAllData() {
      try {
        if (!!this.label && !!this.label.title) {
          this.$store.dispatch('customAttributes/getMetrics', {
            label: this.label.title,
          });
        } else {
          this.$store.dispatch('customAttributes/getMetrics');
        }
        // this.getAttributes();
        // this.getLabels();
        this.componentKey += 1;
      } catch {
        this.showAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
      }
    },
    getAttributes() {
      this.$store.dispatch('attributes/get');
    },
    getLabels() {
      this.$store.dispatch('labels/get');
    },
    downloadReports(fileFormat) {
      const type = 'csat';
      fileFormat = fileFormat.toLowerCase();
      try {
        this.$store.dispatch('csat/downloadCSATReports', {
          fileName: generateFileName({ type, to: this.to, fileFormat }),
          ...this.requestPayload,
          fileFormat,
        });
      } catch (error) {
        this.showAlert(this.$t('REPORT.CSAT_REPORTS.DOWNLOAD_FAILED'));
      }
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getAttributes();
    },
    onFilterChange({ selectedLabel }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'csat',
        });
      }
      this.label = selectedLabel;
      this.getAllData();
    },
  },
};
</script>
