<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-white dark:bg-slate-800 p-2 border border-slate-100 dark:border-slate-700 rounded-md"
  >
    <woot-loading-state
      v-if="isFetching || !metrics"
      class="text-xs"
      :message="$t('REPORT.LOADING_CHART')"
    />
    <div
      v-for="metric in metrics"
      v-else-if="metrics && metrics.length > 0"
      :key="metric.KEY"
      class="p-4 rounded-md mb-3"
    >
      <chart-stats :metric="metric" :is-custom-attributes="true" />
      <div class="mt-4 h-72">
        <woot-loading-state
          v-if="isFetching"
          class="text-xs"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="h-72 flex items-center justify-center">
          <woot-bar
            :key="componentKey"
            :collection="getCollection(metric)"
            :chart-options="getChartOptions(metric)"
            class="h-72 w-full"
          />
        </div>
      </div>
    </div>
    <span v-else class="text-sm text-slate-600">
      {{ $t('REPORT.NO_ENOUGH_DATA') }}
    </span>
  </div>
</template>

<script>
import { METRIC_CHART } from './constants';
import { formatTime } from '@chatwoot/utils';
import reportMixin from 'dashboard/mixins/reportMixin';
import ChartStats from './components/ChartElements/ChartStats.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ChartStats },
  mixins: [reportMixin],
  props: {
    selectedLabel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      componentKey: 0,
      filteredContacts: [],
      totalContactsToMetric: {},
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      meta: 'customAttributes/getMeta',
      metrics: 'customAttributes/getMetrics',
      isFetching: 'customAttributes/getIsFetching',
    }),
    // contacts() {
    //   let result = this.$store.getters["contacts/getAllContacts"];
    //   if (!result) {
    //     return [];
    //   }
    //   if (!!this.selectedLabel && !!this.selectedLabel.title) {
    //     result = result.filter(contact => {
    //       if (!contact.labels) {
    //         return false;
    //       }
    //       return contact.labels?.includes(this.selectedLabel.title) || false;
    //     });
    //   }
    //
    //   return result;
    // },
  },

  watch: {
    // contacts(newContacts, prevContacts) {
    //   if (
    //     !!newContacts &&
    //     JSON.stringify(newContacts) !== JSON.stringify(prevContacts)
    //   ) {
    //     this.componentKey += 1;
    //     // this.fetchLabels(newContacts);
    //   }
    // },
    selectedLabel(newLabel, prevLabel) {
      if (
        !!newLabel &&
        JSON.stringify(newLabel) !== JSON.stringify(prevLabel)
      ) {
        this.componentKey += 1;
        // this.fetchLabels(this.contacts);
      }
    },
  },
  // mounted() {
  //   this.$store.dispatch("contacts/getAll");
  // },
  // unmounted() {
  //   this.$store.dispatch("contacts/reset");
  // },
  // deactivated() {
  //   this.$store.dispatch("contacts/reset");
  // },
  methods: {
    // async fetchLabels(contacts) {
    //   if (!contacts) {
    //     return;
    //   }
    //   await Promise.all(
    //     contacts.map(c => this.$store.dispatch('contactLabels/get', c.id))
    //   );
    //   this.componentKey += 1;
    // },

    shouldCount(contact, attributeKey, label) {
      try {
        return contact.custom_attributes[attributeKey] === label;
      } catch {
        return false;
      }
    },

    countAttributeValue(contacts, attribute, lable) {
      try {
        return (
          contacts.filter(c =>
            this.shouldCount(c, attribute?.attribute_key, lable)
          ).length || 0
        );
      } catch {
        return 0;
      }
    },

    getCollection(metric) {
      let labels = Object.keys(metric.RECORDS);
      const data = labels.map(l => metric.RECORDS[l]);
      if (metric.KEY === 'summary') {
        metric.NAME = this.$t(`REPORT.METRICS.ATTRIBUTES.SUMMERY.NAME`);
        labels = labels.map(l =>
          this.$t(`REPORT.METRICS.ATTRIBUTES.SUMMERY.LABELS.${l}`)
        );
      }
      const datasets = METRIC_CHART.attributes.datasets.map(dataset => {
        return {
          ...dataset,
          yAxisID: 'y-left',
          label: metric.NAME,
          data,
        };
      });
      return {
        labels,
        datasets,
      };
    },
    getChartOptions(metric) {
      let tooltips = {};
      if (this.isAverageMetricType(metric.KEY)) {
        tooltips.callbacks = {
          label: tooltipItem => {
            return this.$t(metric.TOOLTIP_TEXT, {
              metricValue: formatTime(tooltipItem.yLabel),
              conversationCount:
                this.accountReport.data[metric.KEY][tooltipItem.index].count,
            });
          },
        };
      }
      return {
        scales: METRIC_CHART.attributes.scales,
        tooltips: tooltips,
      };
    },
  },
};
</script>
