<template>
  <woot-button
    :variant="variant"
    :class="buttonClass"
    icon="add"
    :size="size"
    @click="addLabel"
  >
    {{ $t('CONTACT_PANEL.LABELS.CONVERSATION.ADD_BUTTON') }}
  </woot-button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'tiny',
    },
    classes: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'smooth',
    },
  },
  computed: {
    buttonClass() {
      return `label--add ${this.classes}`;
    },
  },
  methods: {
    addLabel() {
      this.$emit('add');
    },
  },
};
</script>

<style lang="scss" scoped>
.label--add {
  margin-bottom: var(--space-micro);
  margin-right: var(--space-micro);
}
</style>
